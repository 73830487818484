
.noteToolbar {
    display: none !important;
}

/* .wrapper {
    border: solid 1px green;
    margin-top: 10px;
    
} */

.noteEditor {
    border: solid 1px black;
    height: 200px !important;
    margin-left: 20px !important;
    width: 700px;
}

