/* 예: Quill 에디터의 내용 스타일을 변경 */
.ql-editor {
  font-size: 16px;
  line-height: 1.5;
  color: #fff !important;
  border: none;
  height: 150px;
  width: 100%;
  width: 500px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid rgb(74, 74, 74);
  color: #fff !important;
}

.ql-container.ql-snow {
  border: 1px solid rgb(74, 74, 74);
  color: #fff !important;
}

.ql-toolbar {
  display: none;
}
