
.onetoOnetoolbar {
    display: none !important;
}

/* .wrapper {
    border: solid 1px green;
    margin-top: 10px;
    
} */

.onetooneEditor {
    border: solid 1px black;
    height: 200px !important;
    margin-left: 20px !important;
    width: 600px;
}

